@import "../css/variables.module";

.excalidraw {
  .Avatar {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 100%;
    outline: 2px solid var(--avatar-border-color);
    outline-offset: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $oc-white;
    cursor: pointer;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1;

    &-img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
}

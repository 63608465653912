@import "open-color/open-color";
@import "../css/variables.module";

.excalidraw {
  .layer-ui__wrapper.animate {
    transition: width 0.1s ease-in-out;
  }
  .layer-ui__wrapper {
    // when the rightside sidebar is docked, we need to resize the UI by its
    // width, making the nested UI content shift to the left. To do this,
    // we need the UI container to actually have dimensions set, but
    // then we also need to disable pointer events else the canvas below
    // wouldn't be interactive.
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: var(--zIndex-layerUI);

    &__top-right {
      display: flex;
      gap: 0.75rem;
    }

    &__footer {
      width: 100%;

      &-right {
        z-index: 100;
        display: flex;
      }
    }

    .zen-mode-transition {
      transition: transform 0.5s ease-in-out;

      :root[dir="ltr"] &.transition-left {
        transform: translate(-999px, 0);
      }

      :root[dir="ltr"] &.transition-right {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-left {
        transform: translate(999px, 0);
      }

      :root[dir="rtl"] &.transition-right {
        transform: translate(-999px, 0);
      }

      &.layer-ui__wrapper__footer-left--transition-bottom {
        transform: translate(0, 92px);
      }
    }

    .disable-zen-mode {
      height: 30px;
      position: absolute;
      bottom: 10px;
      [dir="ltr"] & {
        right: 15px;
      }
      [dir="rtl"] & {
        left: 15px;
      }
      font-size: 10px;
      padding: 10px;
      font-weight: 500;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s linear 0s, opacity 0.5s;

      &--visible {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s linear 300ms, opacity 0.5s;
        transition-delay: 0.8s;
      }
    }

    .layer-ui__wrapper__footer-center {
      pointer-events: none;
      & > * {
        pointer-events: all;
      }
    }
    .layer-ui__wrapper__footer-left,
    .layer-ui__wrapper__footer-right,
    .disable-zen-mode--visible {
      pointer-events: all;
    }

    .layer-ui__wrapper__footer-right {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
